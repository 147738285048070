import React from "react";
import { Grid } from "@mui/material";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { themeTokens } from "components/Theme/theme";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { CloudSubscriptionHeader } from "areas/cloudSubscriptions/components/CloudSubscriptionHeader";
import { LegacyBraintreeLicensingChannelView } from "areas/cloudSubscriptions/detail/components/finance/LegacyBraintreeLicensingChannelView";
import { SalesforceContractedLicensingChannelView } from "areas/cloudSubscriptions/detail/components/finance/SalesforceContractedLicensingChannelView";
import { StripeSubscriptionLicensingChannelView } from "areas/cloudSubscriptions/detail/components/finance/StripeSubscriptionLicensingChannelView";
import { TrialLicensingChannelView } from "areas/cloudSubscriptions/detail/components/finance/TrialLicensingChannelView";
import { ImportedTrialLicensingChannelView } from "./components/finance/ImportedTrialLicensingChannelView";
import { ZuoraLicensingChannelView } from "./components/finance/ZuoraLicensingChannelView";

export function LicensingChannelGridView(props: CloudSubscriptionDto) {
  const cloudSubscription = props;

  switch (cloudSubscription.licensingChannel.channelType) {
    case "ZuoraCloudSubscriptionLicensingChannelDto":
      return (
        <ZuoraLicensingChannelView cloudSubscription={cloudSubscription} channel={cloudSubscription.licensingChannel} />
      );
    case "LegacyBraintreeCloudSubscriptionLicensingChannelDto":
      return (
        <LegacyBraintreeLicensingChannelView
          cloudSubscription={cloudSubscription}
          channel={cloudSubscription.licensingChannel}
        />
      );
    case "SalesforceContractedCloudSubscriptionLicensingChannelDto":
      return (
        <SalesforceContractedLicensingChannelView
          cloudSubscription={cloudSubscription}
          channel={cloudSubscription.licensingChannel}
        />
      );
    case "StripeSubscriptionCloudSubscriptionLicensingChannelDto":
      return (
        <StripeSubscriptionLicensingChannelView
          cloudSubscription={cloudSubscription}
          channel={cloudSubscription.licensingChannel}
        />
      );
    case "ImportedTrialCloudSubscriptionLicensingChannelDto":
      return (
        <ImportedTrialLicensingChannelView
          cloudSubscription={cloudSubscription}
          channel={cloudSubscription.licensingChannel}
        />
      );
    case "TrialCloudSubscriptionLicensingChannelDto":
      return (
        <TrialLicensingChannelView cloudSubscription={cloudSubscription} channel={cloudSubscription.licensingChannel} />
      );
  }
}

export function CloudSubscriptionFinance() {
  const { entity: cloudSubscription } = useEntity<CloudSubscriptionDto>();

  // if we don't have a cloud subscription, don't do anything
  if (!cloudSubscription) return <></>;

  return (
    <PolicyAuthorized
      permission={RbacPermissions.CloudSubscription.ViewBilling}
      assetId={cloudSubscription.id}
      subscriptionGroupId={cloudSubscription.subscriptionGroup?.id}
    >
      <CloudSubscriptionHeader pageName={"Billing"} />
      <Grid container sx={{ borderTop: "1px solid", borderColor: themeTokens.color.border.secondary }}>
        <LicensingChannelGridView {...cloudSubscription} />
      </Grid>
    </PolicyAuthorized>
  );
}
